import * as React from "react"
import {SEO} from "../components/seo";

const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: "25%",
  marginBottom: 64,
  maxWidth: "100%",
  fontSize: 64
}
const headingAccentStyles = {
  fontSize: 32,
  fontFamily: "",
  fontWeight: 200,
}
const fancyHandwriting = {
  color: "#663399",
}

const links = [
  {
    text: "Tutorial",
    url: "https://www.gatsbyjs.com/docs/tutorial/",
    description:
      "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
    color: "#E95800",
  },
]

const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}}>
          <h1 style={headingStyles}>
              Winnica <span style={fancyHandwriting}>Żabi Róg</span>
        <br />
        <span style={headingAccentStyles}>Już wkrótce...</span>
      </h1>
</div>

    </main>
  )
}

export default IndexPage

export const Head = () => (
  <SEO title={undefined} description={undefined} pathname={undefined} children={undefined}/>
)